import React from "react";
import './pageBanner.css'

function PageBanner(){
    return(
        <div>
            <div className="pageBanner services_banner">
                <div className="bannerCover d-flex  flex-column justify-content-center align-items-center text-center">
                <span className='span_Titles text-light'><b>Our Innovative Services</b></span>
                <div className="bar mt-2 rounded bg-light"></div>
                    <h2 className='main_heading text-light pt-4'>From Start-Up To Business, A Full Range Of Engineering <br/> 
                        Services Needed By Your Company
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default PageBanner;