import React from "react";

import './infraStructure.css'

function InfraStructure(){
    return(
        <div className="">
            <div className="section_padding">

                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <span className='span_Titles' ><b>Our Company</b></span>
                    <div className="bar mt-2 rounded"></div>
                    <h2 className='main_heading titleFont_style'>Our Infrastructure</h2>
                </div>

                <div className="companyVideo mt-5 d-flex justify-content-center align-items-center text-center">
                    <iframe className="CompVideo" 
                        width='90%'
                        margin='auto'
                        src="https://www.youtube.com/embed/2FyFAoQyie8" 
                        title="Elite Techlogix workplace" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                    </iframe>
                </div>

            </div>

        </div>
    )
}

export default InfraStructure;