import React from "react";

import './map.css'

function LocationMap(){
    return(
        <div className="container-fluid cont section_padding text-center">
            
            <div className="pb-5">
                <h1 className="main_heading">Let's Find Us</h1>
                <div className="bar mt-2 rounded m-auto"></div>
            </div>

            <div className="row g-3">
                <div className="col-md-6">
                    <div className="">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.989602110656!2d74.26963889999999!3d31.46947219999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcc9cb1021cf1e9a3!2zMzHCsDI4JzEwLjEiTiA3NMKwMTYnMTAuNyJF!5e0!3m2!1sen!2s!4v1671830222351!5m2!1sen!2s"  
                        className='map position_box' 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                    </div>
                </div>

                <div className="col-md-6 d-flex align-items-center justify-content-center">

                    <div className="text-start">
                        <span className='span_Titles' ><b>Here We Are</b></span>
                        <div className="bar mt-2 rounded me-auto"></div>
                        <h2 className='main_heading titleFont_style py-4'>Our Office</h2>
                        <p className="text fontSize_sm"> <i class="fa-solid fa-location-dot"></i> 19 H2 - Abdul Haque Rd. Phase 2, Johar Town, Lahore, Pakistan</p>
                            
                        <div className="d-flex flex-column flex-wrap ">
                            <a href="tel:+92-42-35833800" className="nav-link text-dark "><span className="text fontSize_sm "> <i class="fa-solid fa-phone"></i> +92-42-35833800</span></a>
                            <a href="tel:+1 (332) 233-7314" className="nav-link text-dark py-3"><span className="text fontSize_sm "> <i class="fa-solid fa-phone"></i> +1(332) 233-7314 (USA)</span></a>
                            <a className="nav-link text-dark" href="mailto:Info@elitetechlogix.com">
                                <i class="fa-regular fa-envelope"></i><span className="text fontSize_sm mx-2">Info@elitetechlogix.com</span>
                            </a> 
                        </div>
                                                
                    </div>

                </div>
            </div>

        </div>
    )
}

export default LocationMap