import React from "react";
import SiteTabs from "./tabs/tabs";
import './portfolio.css'

function Portfolio(){
    return(
        <div className="section_padding">   
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <span className='span_Titles' ><b>We Develop That Convert</b></span>
                <div className="bar mt-2 rounded"></div>
                <h2 className='main_heading titleFont_style pt-2'>Our Portfolio</h2>
                <p className="text py-3">We help our customers grow with state-of-the-art technology, state-of-the-art designs, and innovative<br/> software solutions.</p>
            </div>
            
            <SiteTabs />

        </div>
    )
}

export default Portfolio