import React from "react";
import '../ourCasesSection.css'
import case_2 from '../assets/t1.png'

function Case2(){
    return(
        <div className="row py-5 w-100 m-0 ">

        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
                <div className='service_imgBox'> 
                    <img src={case_2} className='w-100' alt=""  /> 
                </div>
            </div>

            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center">
                <div className='AboutInfo_box'>
                    <span className='span_Titles'>Call Centre</span>
                    <div className="bar rounded"></div>
                    <h2 className='sub_titles titleFont_style pt-3'>Microsoft TakeLessons:</h2>
                    <p className='text_p'>Microsoft’s Take Lessons has been one of the most amazing clients we had the opportunity to work with. 
                        Our Tele Sales agents were not only able to achieve the sales target given to them but also go above and beyond that target every month. Our 
                        agents were able to make hundreds of sales per month for them and in the process help many more students who were looking to learn new skills.
                    </p>
                    <p className='text_p'>
                        We handled both inbound and outbound sales for them and our Team Leader was also able to help them with many other tasks as well including keeping track of sales and doing QA for them on the calls that were being made. 
                    </p>

                    <div className="caseList">
                        <ul className="all_type_list p-0">
                            <li className="casesList py-1"><i class="fa-solid fa-headphones text_gradient"></i> Telemarketing </li>
                            <li className="casesList py-1"><i class="fa-regular fa-calendar-check text_gradient"></i> Appointment Setting </li>
                            <li className="casesList py-1"><i class="fa-solid fa-shield-halved text_gradient"></i> Virtual Assistants </li>
                        </ul>
                    </div>

                </div>
            </div>

            

        </div>
    )
}

export default Case2;