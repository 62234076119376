import React from "react";
import './position.css';

function Positions(){
    return(
        <div className="section_padding">
            <div className="d-flex flex-column justify-content-center align-items-center text-center pb-5">
                <span className='span_Titles' ><b>Careers</b></span>
                <div className="bar mt-2 rounded"></div>
                <h2 className='main_heading titleFont_style pt-2'>Join Us And Be A Part Of Elite Squad</h2>
                <i class="fa-solid fa-arrow-down text_gradient arrowDown"></i>
            </div>

                

           <div className="cont p-cont">
                <div className="row g-3 justify-content-center align-items-center">
                    <div className="col-md-12 col-lg-2">
                        <p className="position_title">Position</p>
                        <p className="position_txt">Mern Stack Developer</p>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <p className="position_title">Address</p>
                        <p className="position_txt">Lahore Office</p>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <p className="position_title">Job Description</p>
                        <p className="text_p">We are looking for a Full Stack Developer. The ideal candidates should be enthusiastic about working with AI/ML, IoT, and web stack technologies and eager to adapt across diverse projects and technologies.                     
                        </p>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="d-flex justify-content-end"><a className="btn rounded mx-1 blue_btn " href="/contact" target="_blank" >Apply Now <i class="fa-solid fa-arrow g-3-right ms-2"></i> </a> </div>
                    </div>
                </div>
           </div>

           <div className="cont p-cont">
                <div className="row g-3 justify-content-center align-items-center">

                    <div className="col-md-12 col-lg-2">
                        <p className="position_title">Position</p>
                        <p className="position_txt">Full Stack Developer</p>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <p className="position_title">Address</p>
                        <p className="position_txt">Lahore Office</p>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <p className="position_title">Job Description</p>
                        <p className="text_p">We’re seeking a highly knowledgeable and experienced full-stack developer with strong NodeJS experience who enjoys early-stage environments that provide freedom to move fast and ship new customer-facing products and features at high velocity.                     
                        </p>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="d-flex justify-content-end"><a className="btn rounded mx-1 blue_btn " href="/contact" target="_blank" >Apply Now <i class="fa-solid fa-arrow-right ms-2"></i> </a> </div>
                    </div>
                </div>
           </div>
















            {/* <div className="px-3">

            <div className="position_box px-3 container rounded-4">
                <div className="row">
                    <div className="col-md-4">
                        <div>
                            <h2 className="small_titles">Mern Stack Developer</h2>
                            <p className="text py-3">1 Position <br/>Lahore office</p>    
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div>
                            <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, 
                                pulvinar dapibus leo.ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper 
                                mattis, pulvinar dapibus leo.
                            </p>
                            <div className="d-flex justify-content-end"><a className="btn rounded mx-1 blue_btn " href="/contact" target="_blank" >Apply Now <i class="fa-solid fa-arrow-right ms-2"></i> </a> </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="position_box px-3 container rounded-4 my-4">
                <div className="row">
                    <div className="col-md-4">
                        <div>
                            <h2 className="small_titles">Full Stack Developer</h2>
                            <p className="text py-3">1 Position <br/>Lahore office</p>    
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div>
                            <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, 
                                pulvinar dapibus leo.ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper 
                                mattis, pulvinar dapibus leo.
                            </p>
                            <div className="d-flex justify-content-end"><a className="btn rounded mx-1 blue_btn " href="/contact" target="_blank" >Apply Now <i class="fa-solid fa-arrow-right ms-2"></i> </a> </div>
                        </div>
                    </div>
                </div>

            </div>


            </div> */}


        </div>
    )
}

export default Positions;