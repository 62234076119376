import React from "react";
import './ourSpace.css'


function OurSpace(){
    return(
        <div className="py-3">

            <div className="d-flex flex-column justify-content-center align-items-center">
                <span className='span_Titles' ><b>How We Work</b></span>
                <div className="bar mt-2 rounded"></div>
                <h2 className='main_heading titleFont_style pt-3'>Our Space</h2>
            </div>

            <div className="container pt-4">
                <div className="row g-2">
                    <div className="col-md-4"> <div className="lf_box overflow-hidden rounded"> <div className="lifeImgs_box rounded lifeImgs-1"></div> </div> </div>
                    <div className="col-md-4"> <div className="lf_box overflow-hidden rounded"> <div className="lifeImgs_box rounded lifeImgs-2"></div> </div> </div>
                    <div className="col-md-4"> <div className="lf_box overflow-hidden rounded"> <div className="lifeImgs_box rounded lifeImgs-3"></div> </div> </div>
                    <div className="col-md-4"> <div className="lf_box overflow-hidden rounded"> <div className="lifeImgs_box rounded lifeImgs-4"></div> </div> </div>
                    <div className="col-md-4"> <div className="lf_box overflow-hidden rounded"> <div className="lifeImgs_box rounded lifeImgs-5"></div> </div> </div>
                    <div className="col-md-4"> <div className="lf_box overflow-hidden rounded"> <div className="lifeImgs_box rounded lifeImgs-6"></div> </div> </div>
                </div>
            </div>

        </div>
    )
}

export default OurSpace;