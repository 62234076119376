import React from "react";

import { useCountUp } from "react-use-count-up";

function CounterBox(){
          
    const countNum1 = useCountUp({ start: 0, end: 300, duration: 1000 });
    const countNum2 = useCountUp({ start: 0, end: 1575, duration: 1000 });
    const countNum3 = useCountUp({ start: 0, end: 633, duration: 1000 });    

    return(
       
        <div className="bb">

            <section class="charts_orb">
                <article class="orb">
                    <div class="orb_graphic">
                        <svg>
                            <circle class="fill"></circle>
                            <circle class="progress"></circle>
                        </svg>
                        <div class="orb_value count"><div>{countNum1}+</div></div>
                    </div>
                    <div class="orb_label">
                        <p className="text">Employees</p>
                    </div>
                </article>
                
                <article class="orb">
                    <div class="orb_graphic">
                        <svg>
                            <circle class="fill"></circle>
                            <circle class="progress"></circle>
                        </svg>
                        <div class="orb_value count"><div>{countNum2}+</div></div>
                    </div>
                    <div class="orb_label">
                    <p className="text">Total Clients Served</p>
                    </div>
                </article>
                
                <article class="orb">
                    <div class="orb_graphic">
                        <svg>
                            <circle class="fill"></circle>
                            <circle class="progress"></circle>
                        </svg>
                        <div class="orb_value count"><div>{countNum3}K+</div></div>
                    </div>
                    <div class="orb_label">
                        <p className="text">Upwork Hours</p>
                    </div>
                </article>
                
            </section>
            
        </div>
    )

    
}


export default CounterBox;