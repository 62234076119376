import React from "react";

import Case1 from "./Casses/case1";
import Case2 from "./Casses/case2";
import Case3 from "./Casses/case3";
// import Case4 from "./Casses/case4";

function OurCasesSection(){
    return(
            <div className="section_padding">

                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <span className='span_Titles' ><b>Recent Work</b></span>
                    <div className="bar mt-2 rounded"></div>
                    <h2 className='main_heading titleFont_style py-3'>Our Cases</h2>
                    <p className="text ">Learn how we help customers overcome the challenges of their business, across all industries.</p>
                </div>
                
                    <div className="s-box s-box1 my-5"><Case3 /></div>
                    <div className="s-box s-box2 my-5"><Case2 /></div>
                    <div className="s-box s-box1 my-5"><Case1 /></div>
                    {/* <Case4 /> */}

                <div className="d-flex justify-content-center">
                     <a href="/work" className="btn blue_btn rounded">View More <i class="fa-solid fa-arrow-right ms-2"></i> </a> 
                </div>
            </div>

    )
}

export default OurCasesSection;