import React from "react";
import './footer.css'
import FooterLogo from '../assets/logoWhite.webp'
import upWorkImg from '../assets/upWork_white.webp'


function Footer(){
    return(

     <div>
           <div className="section_padding">
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <span className='span_Titles' ><b>Not Sure Where To Start?</b></span>
                    <div className="bar mt-2 rounded"></div>
                    <h2 className='sub_titles py-4'>10 years of building digital products and we are just<br/> getting started!</h2>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-wrap pt-3">
                    <a href="mailto:Info@elitetechlogix.com" className="btn rounded mx-1 blue_btn ">REQUEST AN ESTIMATION <i class="fa-solid fa-arrow-right ms-2"></i> </a> 
                    <a href="mailto:Info@elitetechlogix.com" target='_blank' className="btn mx-1 white-Btn gradient_footerBtn">SCHEDULE A CALL <i class="fa-solid fa-arrow-right ms-2"></i></a>
                </div>
            </div>

                <div className="footer-Section text-light d-flex flex-column justify-content-between align-items-center">
                    <h1 className="text-center footer_m_title main_heading text-light">Join our social accounts to receive the news &<br/> latest trends</h1>

                    <div className="row px-3 row_width">
                        <div className="col-md-3">
                            <div className="footer_navigation m-auto">
                                <h2 className="sub_titles footer_titles_sm text-light pb-4">Useful Links</h2>
                                <ul className="all_type_list text-s d-flex flex-column flex-wrap p-0">
                                    <li className="text m-1" ><a  className="nav-link" href="/">Home</a></li>
                                    <li className="text m-1" ><a  className="nav-link" href="/services">Services</a></li>
                                    <li className="text m-1" ><a  className="nav-link" href="/work">Work</a></li>
                                    <li className="text m-1" ><a  className="nav-link" href="/about-us">About Us</a></li>
                                    <li className="text m-1" ><a  className="nav-link" href="/career">Career</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="d-flex flex-column">
                                <h2 className="sub_titles footer_titles_sm text-light pb-4">Head Office</h2>
                                <a className="nav-link py-3" target="_blank" href="https://www.google.com/maps/place/31%C2%B028'10.1%22N+74%C2%B016'10.7%22E/@31.4694806,74.2674613,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x978c38ea0cbeb87d!8m2!3d31.4694806!4d74.26965?hl=en">
                                    <i class="fa-solid fa-location-dot"></i> 
                                    <span className="text fontSize_sm mx-2">19 H2 - Abdul Haque Rd. Phase 2, Johar Town, Lahore, Pakistan</span>
                                </a>

                                <div className="d-flex flex-wrap flex-column  ">
                                    <a href="tel:+92-42-35833800" className="nav-link pb-3"><span className="text fontSize_sm "> <i class="fa-solid fa-phone"></i> +92-42-35833800</span></a>
                                    <a href="tel:+1 (332) 233-7314" className="nav-link"><span className="text fontSize_sm "> <i class="fa-solid fa-phone"></i> +1(332) 233-7314 (USA)</span></a>
                                
                                    <a className="nav-link" href="mailto:Info@elitetechlogix.com">
                                        <i class="fa-regular fa-envelope py-3"></i>
                                    <span className="text fontSize_sm mx-2">Info@elitetechlogix.com</span>
                                    </a>
                                </div>

                                
                                {/* <div className="thumb_bar footerBar w-50 my-3 " ></div> */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                            <h2 className="sub_titles footer_titles_sm text-light pb-4">About Company</h2>
                                <a href="/" className="nav-link">
                                <div className=" footerLogo_res d-flex align-items-center pt-3">
                                    <div className="footer_Logo LogoBox " ><img src={FooterLogo} className='w-75' alt="" /> </div>
                                    <h2 className="main_heading logo_title text-light ">Elite Techlogix</h2>
                                </div>
                                </a>
                                <p className="py-4">Elite Techlogix is leading and evolving the traditional way organizations used to deal with their consumers.</p>

                                <div className="footerIcon_box d-flex">
                                    <a href="https://www.facebook.com/elitetechlogix/" target="_blank" > <i class="fa-brands fa-facebook-f footerIcons text-light"></i> </a>
                                    <a href="https://www.linkedin.com/company/elite-techlogix/mycompany/" target="_blank" ><i class="fa-brands fa-linkedin-in footerIcons text-light px-4"></i></a>
                                    {/* <a href="https://www.linkedin.com/company/elite-techlogix/mycompany/" target="_blank" ><i class="fa-brands fa-upwork footerIcons text-light px-4"></i></a> */}
                                <div className="LogoBox"><a href="https://www.upwork.com/o/companies/~019ebaa21af2dd30d7/" target="_blank" ><img src={upWorkImg} alt="" className="w-50" /></a></div>
                                </div>

                            </div>
                        </div>

                        

                    </div>

                        <div className="copyRightBar text-center p-2">
                            <p className="text1 text-light">© Copyright 2023 – Elite Techlogix. All Rights Reserved.</p>
                        </div>
                </div>
{/* 
                <div className="copyRightBar text-center p-2 bg-light">
                    <p className="text-dark text p-0 m-0">© Copyright 2023 – Elite Techlogix</p>
                </div> */}
     
    
     </div>
    )
}

export default Footer;