import React from "react";
import {Link} from 'react-router-dom'

function CallCentre(){
    return(
        <div>
            <div className="container">
                <div className="row p-4 g-4">

                    <div className="col-lg-4">
                        <Link  to="/project-detail/Microsoft's Take Lessons">
                            <div className="servicesBox portfolioBox takeLessons rounded-4 ">
                                <div className="portFolio_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                    <h2 className='small_titles titleFont_style text-light'>MICROSOFT's TAKE LESSONS</h2>                       
                                </div>
                            </div>
                        </Link>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default CallCentre;