import React from "react";
import CustomerFeedBack from "../homePage/customerFeedBack/customerFeedBack";
// import Address from "./officeAddress/address";
// import Form from "./form/form";
import PageBanner from "./pageBanner/pageBanner";
// import ContactInfo from "./contactInfo/contactInfo";
import LocationMap from "./officeAddress/map/map";

function Contact(){
    return(
        <div>
            <PageBanner />
            {/* <Form /> */}
            {/* <ContactInfo /> */}
            {/* <Address /> */}
            <LocationMap />
            <CustomerFeedBack />
        </div>
    )
}

export default Contact;