import React from "react";
import CounterBox from "./counterBox/counterBox";
import './counterSection.css'

function CounterSection(){
    return(
        <div className="countBox_bg">
            <div className="counter-bar section_padding text-center d-flex flex-column align-items-center justify-content-center">

                <p className="text counter_text text-dark">Here are some of the snapshot of our success over the years.</p>
                <div className="d-flex align-items-center  my-3">
                    <div className="thumb_bar " ></div>
                    <i class="fa-regular fa-thumbs-up px-3 iconSize text_gradient "></i>
                    <div className="thumb_bar " ></div>
                </div>

                    <CounterBox />
            </div>
        </div>
    )
}

export default CounterSection;