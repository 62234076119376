import Accordion from 'react-bootstrap/Accordion';

function Accordian() {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h4 className='span_Titles text-light'>What’s your turnaround time?</h4></Accordion.Header>
        <Accordion.Body>
        <p className='text'>
            The average turnaround time for starter websites is 10 business days. Timelines will 
            vary depending on the project brief and our availability. we will provide a concrete timeline before the project starts.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> <h4 className='span_Titles text-light'>How do i make payments</h4> </Accordion.Header>
        <Accordion.Body>
        <p className='text'>
            Once you’re ready to move forward, you will make a payment to Easol. They will hold your payment and only 
            release it to me when project milestones are completed.
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Accordian;