import React from "react";
import LeaderShip from "../aboutUs/leaderShip/leaderShip";
import Environment from "./environment/environment";
import OurSpace from "./ourSpace/ourSpace";

import PageBanner from "./pageBanner/pageBanner";
// import Video from "./video/video";

function LifeAtEliteTechlogix(){
    return(
        <div>
            <PageBanner />
            <Environment />
            {/* <Video/> */}
            <LeaderShip />
            <OurSpace />
            
        </div>
    )
}

export default LifeAtEliteTechlogix