import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import All from './all';
import CallCentre from './callCentre';
import LeadGeneration from './leadGeneration';

import './tabs.css'
import Websites from './websites';


function SiteTabs() {
  const [key, setKey] = useState('all');

  return (
    <div className="container tb">
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >

        <Tab eventKey="all" title="All" className='text-dark'>
           <All />
        </Tab>

        <Tab eventKey="websites" title="Websites">
        <Websites />
        </Tab>

        <Tab eventKey="lead generation" title="Lead Generation" >
            <LeadGeneration />
        </Tab>

        <Tab eventKey="call centre" title="Call Centre" >
          <CallCentre />
        </Tab>

        

    </Tabs>
    </div>
  );
}

export default SiteTabs;