import React from "react";
// import {Button} from 'react-bootstrap'
import './servicesSection.css';
import lead from '../homePageAssets/lead-generation.svg';
import web from '../homePageAssets/web-development.svg';
import digital from '../homePageAssets/marketing-automation.svg';
import callCenter from '../homePageAssets/call-center.svg'

// import {Link} from 'react-router-dom';
// import img1 from '../homePageAssets/1 (1).png'



function ServicesSection(){
    return(
        <div className="cont">
            <div className="section_padding text-center">
                    {/* <div className="d-flex flex-column justify-content-center align-items-center pb-5">
                        <span className='span_Titles' ><b>What We’re Offering</b></span>
                        <div className="bar mt-2 rounded"></div>
                        <h2 className='main_heading titleFont_style'>Services We’re Providing</h2>
                    </div> */}


                    <div className="row text-start mt-5 g-3">
                        <div className="col-md-7 fullCol_md">
                            <div className="service_sideCont mt-5">
                                <span className='span_Titles' ><b>What We’re Offering</b></span>
                                <div className="bar mt-2 rounded"></div>
                                <h2 className='main_heading titleFont_style py-3'>Services We’re Providing</h2>
                                <p className="mt-5">We bring you award-winning products, advanced UI / UX, and world-class web talent and mobile platforms that bridge the gap between the creative world and technology. At Elite Techlogix, our solutions become the kind of technology that others are promoting.</p>
                                <p className="">We offer a complete range of “BPO” solution services which are rooted by our expertise
                                and subtle knowledge of customer behavior and the business industry.</p>

                                <a href="/services" className="btn blue_btn rounded mt-5"  >View All Services <i class="fa-solid fa-arrow-right ms-2"></i> </a> 

                            </div>
                        </div>
                        <div className="col-md-5 d-flex fullCol_md column_md">

                            <div className="ServiceSide_cols topMargin">
                                <div className="row g-3">
                                <div className="col-md-12">
                                    <div className="ss_boxN border_gradient">
                                        <div className="Ser_iconImg"><img src={lead} alt="" className="w-100" /></div>
                                        <h4 className="short_titles pt-3">Lead Generation</h4>
                                        <p>The action or process of identifying and cultivating potential customers for a business’s products or services.</p>
                                    </div>
                                </div>
                                <div className="col-md-12 dd">
                                    <div className="ss_boxN border_gradient">
                                        <div className="Ser_iconImg"><img src={web} alt="" className="w-100" /></div>
                                        <h4 className="short_titles pt-3">Web Development</h4>
                                        <p>Designing a mobile app is not easy, but using it should be. Customers expect the best mobile experience ...</p>
                                    </div>
                                </div>
                                </div>
                            </div>


                            <div className="ServiceSide_cols ServiceSide_col_2 ">
                                <div className="row g-3 flex-column">
                                <div className="col-md-12">
                                    <div className="ss_boxN border_gradient">
                                        <div className="Ser_iconImg"><img src={callCenter} alt="" className="w-100" /></div>
                                        <h4 className="short_titles pt-3">Call Center</h4>
                                        <p>UI/UX Design is the process of visual communication and problem-solving through the use of typography, photography, and illustration...</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="ss_boxN border_gradient">
                                        <div className="Ser_iconImg"><img src={digital} alt="" className="w-100" /></div>
                                        <h4 className="short_titles pt-3">Digital Marketing</h4>
                                        <p>UI/UX Design is the process of visual communication and problem-solving through the use of typography, photography, and illustration...</p>
                                    </div>
                                </div>
                            </div>
                            </div>



                            



                            




                        </div>
                    </div>

                    {/* <div className="row section_padding g-3 mt-5">

                        <div className="col-lg-3 col-md-6 s_BB">     
                            <div className="outer_box">
                                <div className="sBox_imgs"> <img src={img1} alt="" className="w-100" /> </div>

                                <div className="inner_box inner_box1">
                                    <div className="innerBox_txt text-light">
                                        <h4 className="text-light">Lead Generation</h4>
                                        <p className="py-2">The action or process of identifying and cultivating potential customers for a business’s products or services.</p>
                                        <Link to='/services/#lead'> <button className="btn innerBox_btn">Learn More</button> </Link>                         
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 s_BB">     
                            <div className="outer_box">
                                <div className="sBox_imgs"> <img src={img1} alt="" className="w-100" /> </div>
                                <div className="inner_box inner_box2">
                                    <div className="innerBox_txt text-light">
                                        <h4 className="text-light">Web Development</h4>
                                        <p className="py-2">Designing a mobile app is not easy, but using it should be. Customers expect the best mobile experience ...</p>
                                        <Link to='/services/#lead' > <button className="btn innerBox_btn">Learn More</button> </Link>                         
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 s_BB">     
                            <div className="outer_box">
                                <div className="sBox_imgs"> <img src={img1} alt="" className="w-100" /> </div>
                                <div className="inner_box inner_box3">
                                    <div className="innerBox_txt text-light">
                                        <h4 className="text-light">Call Center</h4>
                                        <p className="py-2">UI/UX Design is the process of visual communication and problem-solving through the use of typography, photography, and illustration...</p>
                                        <Link to='/services/#lead'> <button className="btn innerBox_btn">Learn More</button> </Link>                         
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 s_BB">     
                            <div className="outer_box">
                                <div className="sBox_imgs"> <img src={img1} alt="" className="w-100" /> </div>
                                <div className="inner_box inner_box4">
                                    <div className="innerBox_txt text-light">
                                        <h4 className="text-light">Digital Marketing</h4>
                                        <p className="py-2">UI/UX Design is the process of visual communication and problem-solving through the use of typography, photography, and illustration...</p>
                                        <Link to='/services/#lead'> <button className="btn innerBox_btn">Learn More</button> </Link>                         

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> */}

                    










{/* 

                <div className="row px-3 py-4 g-3 ">
 
                    <div className="col-lg-4">
                    <div className="servicesBox servicesBox-6 rounded ">
                            <div className="cover_Overlay services_overlay rounded d-flex justify-content-center align-items-end">
                                <h2 className='sub_titles titleFont_style  text-light py-2'>Lead Generation</h2>
                            </div>
                            <div className="cover_Overlay servicesInfo_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                <h2 className='sub_titles titleFont_style text-light'>Lead Generation</h2>
                                <p className="text-light">The action or process of identifying and cultivating potential customers for a business’s products or services.</p>
                                <Link to='/services/#lead'> <button className="btn quoteBtn servicesBox_btn">Learn More</button> </Link>                         
                            </div>
                        </div>
                    </div>

        
                    <div className="col-lg-4">
                    <div className="servicesBox servicesBox-2 rounded ">
                            <div className="cover_Overlay services_overlay rounded d-flex justify-content-center align-items-end">
                                <h2 className='sub_titles titleFont_style  text-light py-2'>Mobile App Development</h2>
                            </div>
                            <div className="cover_Overlay servicesInfo_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                <h2 className='sub_titles titleFont_style text-light'>Mobile App Developmentt</h2>
                                <p className="text-light">Designing a mobile app is not easy, but using it should be. Customers expect the best mobile experience ...</p>
                                <Link to='/services/#Mob-development'><button className="btn quoteBtn servicesBox_btn">Learn More</button> </Link>                         
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4">
                    <div className="servicesBox servicesBox-3 rounded ">
                            <div className="cover_Overlay services_overlay rounded d-flex justify-content-center align-items-end">
                                <h2 className='sub_titles titleFont_style  text-light py-2'>UI/UX Design</h2>
                            </div>
                            <div className="cover_Overlay servicesInfo_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                <h2 className='sub_titles titleFont_style text-light'>UI/UX Design</h2>
                                <p className="text-light">UI/UX Design is the process of visual communication and problem-solving through the use of typography, photography, and illustration...</p>
                                <Link to='/services/#UI_design'> <button className="btn quoteBtn servicesBox_btn">Learn More</button> </Link>                        
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-5 px-3 g-3" >

                    <div className="col-lg-4">
                        <div className="servicesBox servicesBox-4 rounded ">
                            <div className="cover_Overlay services_overlay rounded d-flex justify-content-center align-items-end">
                                <h2 className='sub_titles titleFont_style  text-light py-2'>Digital Marketing</h2>
                            </div>
                            <div className="cover_Overlay servicesInfo_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                <h2 className='sub_titles titleFont_style text-light'>Digital Marketing</h2>
                                <p className="text-light">We are most famous and popular in pakistan. we place value on our ability to assess, plan, and deliver on our seo and digital marketing promises...</p>       
                                <Link to='/services/#digi_mark'> <button className="btn quoteBtn servicesBox_btn">Learn More</button> </Link>                    
                            </div>
                        </div>
                    </div>
                 
                    <div className="col-lg-4">
                    <div className="servicesBox servicesBox-5 rounded ">
                            <div className="cover_Overlay services_overlay rounded d-flex justify-content-center align-items-end">
                                <h2 className='sub_titles titleFont_style  text-light py-2'>Call Center</h2>
                            </div>
                            <div className="cover_Overlay servicesInfo_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                <h2 className='sub_titles titleFont_style text-light'>Call Center</h2>
                                <p className="text-light">A call center is a centralized office used for receiving or transmitting a large volume of inquiries by telephone.</p>
                                <Link to='/services/#call_center'> <a className="btn quoteBtn servicesBox_btn">Learn More</a> </Link>                        
                            </div>
                        </div>
                    </div>

             
                    <div className="col-lg-4">
                        <div className="servicesBox servicesBox-1 rounded ">
                            <div className="cover_Overlay services_overlay rounded d-flex justify-content-center align-items-end d-flex justify-content-center align-items-end">
                                <h2 className='sub_titles titleFont_style text-light py-2'>Web Development</h2>
                            </div>
                            <div className="cover_Overlay servicesInfo_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                <h2 className='sub_titles titleFont_style text-light'>Web Development</h2>
                                <p className="text-light">We are offering professional web design services.No matter what your needs, a personal website or corporate community website ...</p>         
                                <Link to="/services/#web-development" > <button className="btn quoteBtn servicesBox_btn">Learn More</button> </Link>                          
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <a href="/services" className="btn blue_btn rounded"  >View All Services <i class="fa-solid fa-arrow-right ms-2"></i> </a>  */}

            </div>
            
        </div>
    )
}

export default ServicesSection;