import React from "react";
import './leaderShip.css'

import CEO_img from '../aboutAssets/CEO.webp'

function LeaderShip(){
    return(
       <div className="container section_padding">

        <div className="row section_padding w-100">
            <div className="col-md-6">
                <div className="leaderImg_box m-auto w-100" >
                    <img src={CEO_img} className='w-100' alt="" />
                    <div className="frame"></div>
                </div>
            </div>

            <div className="col-md-6 d-flex justify-content-center">
                <div className='AboutInfo_box d-flex flex-column justify-content-center'>
                    <span className='span_Titles' >CEO</span>
                    <div className="bar mt-2 rounded"></div>
                    <h2 className='main_heading titleFont_style UpperCase_title py-3'>Meet Our Leadership</h2>
                    <h2 className="text_gradient">Shahid Zaman Malik</h2>
                    <p className="text-dark">Founder & Business Manager</p>
                </div>
            </div>

        </div>

            

            
       


            {/* <div className='AboutInfo_box d-flex flex-column align-items-center justify-content-center'>
                    <span className='span_Titles' >CEO</span>
                    <div className="bar mt-2 rounded"></div>
                    <h2 className='main_heading titleFont_style UpperCase_title py-3'>Meet Our Leadership</h2>
            </div>

            <div className="leaderShip_box m-auto rounded p-2 bg-light">
                <div className="leaderImg_box m-auto w-100" >
                    <div className="ceoCoverBox"></div> <img src={CEO_img} className='w-100 rounded' alt="" />
                </div>
                <div className="p-3">
                    <h2 className="sub_titles titleFont_style">Shahid Zaman Malik</h2>
                    <p className="text Blue_Span">Founder & Business Manager</p>
                </div>
            </div> */}

            {/* ================================== */}

       </div>
    )
}

export default LeaderShip;