import React, {useState, useEffect} from "react";
import './projectDetail.css'

import { useParams } from "react-router-dom";
import HeaderBlue from "../../../header/headerBlue";

const LOCAL_BASE_URL= 'http://localhost:3000/';
const LIVE_BASE_URL= 'https://elitetechlogix.com/'


function ProjectDetail(){



    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    const [project,setProject] = useState( null )
    const [loading,setLoading] = useState(false)

    let { title } = useParams();
    console.log(title)

    useEffect(() => {
        setLoading(true);
        fetch("/data.json")
          .then((response) => response.json())
          .then((data) => {
            const filteredData = data.find((obj) => obj.title.includes(title));
            console.log({ filteredData });
            setProject(filteredData);
            setLoading(false);
        });
          
    }, []);
 

    return(
        <div>

            <HeaderBlue/>

            { loading ? <> loading </> : 

                <div className="container section_padding text-center"> 
                        
                    <div className="d-flex flex-column justify-content-center align-items-center text-center projectDetail_page">
                        <span className='span_Titles' ><b>{project?.industry}</b></span>
                        <div className="bar mt-2 rounded"></div>
                        <h3 className='main_heading titleFont_style pt-2'> {project?.title} </h3>
                    </div>

                    <div className="projectImg_box py-5">
                        <img src={LIVE_BASE_URL + project?.project_img } className="w-100 ColSide_img rounded" alt=""/>
                    </div>

                    <div className="contentBox">
                        <p className="text">{project?.text}</p>
                        <p className="text">{project?.text2}</p>
                        <h4>{project?.c}</h4>
                    </div>

                    <div className="caseList py-3">
                        <ul className="all_type_list d-flex flex-wrap justify-content-evenly p-0">
                            <li className="casesList py-2"><i dangerouslySetInnerHTML={{ __html: project?.icon }} ></i> {project?.core_point1}</li>
                            <li className="casesList py-2"><i dangerouslySetInnerHTML={{ __html: project?.icon2 }} ></i>{project?.core_point2}</li>
                            <li className="casesList py-2"><i dangerouslySetInnerHTML={{ __html: project?.icon3 }} ></i>{project?.core_point3}</li>
                        </ul>
                    </div>

                </div>
            }

        </div>
        
    )
}

export default ProjectDetail;