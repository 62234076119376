import React from "react";
import './banner.css'

import upWorkImg from '../homePageAssets/upWork_white.webp'


function Banner(){
    return(
        <div>

           <div class="banner">
                <div class="banner_cover">
                    <h1 className='banner_text text-light titleFont_style'>INOVATIVE SOLUTIONS FOR <br/> <span className='bold_title'>LEAD GENERATION</span> </h1>
                    <p className='text text-light py-4'> We offer a complete range of “BPO” solution services which are rooted by our expertise <br/>
                        and subtle knowledge of customer behavior and the business industry.
                    </p>
                    <a href='mailto:info@elitetechlogix.com' className="btn navBtn rounded">GET A QUOTE <i class="fa-solid fa-arrow-right ms-2"></i> </a> 

                    <div className="footerIcon bannerIcon_bar text-light_box">
                        <a href="https://www.facebook.com/elitetechlogix/" target="_blank"> <i class="fa-brands fa-facebook footerIcons text-light"></i> </a>
                        <a href="https://www.linkedin.com/company/elite-techlogix/mycompany/" target="_blank"><i class="fa-brands fa-linkedin footerIcons text-light py-4"></i></a>
                        <div className="LogoBox"><a href="https://www.upwork.com/o/companies/~019ebaa21af2dd30d7/" target="_blank"><img src={upWorkImg} alt="" className="w-75" /></a></div>
                    </div>
                </div>
            </div>
 
        </div>
    )
}

export default Banner;